import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {environment} from '../environments/environment';
import {MessageService} from 'primeng/api';
import {ServiceProviderBidsService} from "./services/service-provider-bids.service";
import {ErrorHandlerService} from "./services/error-handler.service";
import {OneSignalService} from "./services/onesignal.service"

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'logibids-client';
    ltr: boolean;
    rtl: boolean;
    validate;
    locator: any;

    constructor(private router: Router,
                private auth: AuthService,
                private ms: MessageService,
                private spb: ServiceProviderBidsService,
                private errorHandler: ErrorHandlerService,
                private onesignal: OneSignalService,
                private permissionsService: NgxPermissionsService) {
    }

    ngOnInit() {
        // this.validateToken();
        this.setLanguage();
        this.removeScript();
        this.setPermission();
        this.onesignalCheck();
    }

    setPermission() {
        const user = localStorage.getItem('user');
        if (user) {
            const {type} = JSON.parse(user);
            const perm = new Array(type);
            this.permissionsService.loadPermissions(perm);
            if (type === 'CLIENT') {
                localStorage.clear();
                this.router.navigate(['/get-started']);
                this.ms.add({
                    severity: 'error',
                    summary: 'Session Expired'
                });
            }
        }
        else {
            this.router.navigate(['/login']);
        }
    }

    ngOnDestroy() {
        clearInterval(this.validate);
        navigator.geolocation.clearWatch(this.locator);
    }

    setLanguage() {
        const path = window.location.pathname.split('/');
        const supportedLanguage = ['ar', 'ur', 'en'];
        const rtlLang = ['ar', 'ur']; // Check included langauge
        if (supportedLanguage.includes((path[1]))) {
            localStorage.setItem('language', path[1]);
            if (rtlLang.includes(path[1])) { // Check direction
                this.rtl = true;
                this.ltr = false;
            } else {
                this.rtl = false;
                this.ltr = true;
            }
        }
    }

    removeScript() { // This script is to reload map with selected language
        const script = document.createElement('script');
        const language = localStorage.getItem('language') || 'en';
        script.src =
            `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.GOOGLE_API_KEY}
      &libraries=places&language=${language}`;
        document.head.appendChild(script);
    }

    validateToken = () => {
        const token = localStorage.getItem('accessToken');
        const that = this;
        if (token) {
            that.auth.currentUserInfo().subscribe((status) => {
                if (!status) {
                    that.router.navigate(['/get-started']);
                } else {
                    console.log(status);
                }
            });
            that.validate = setInterval(() => {
                that.validateToken();
            }, 100000);
        }
    };
    onesignalCheck(){
        let onesignalToken=localStorage.getItem('onesignalToken');
        let user:any = JSON.parse(localStorage.getItem('user'));
        let notify=localStorage.getItem('notify');
        console.log('user : ',user)
        console.log('Service worker')
        if(user){
          console.log('user tokens: ',user.playerids);
          console.log('Onesignal Token: ',onesignalToken);
          console.log('Subscribed : ',user.playerids.includes(onesignalToken))
          if(onesignalToken && onesignalToken.length>8){
            console.log('Notify :',notify)
            if (notify=='granted'){
              if(user.playerids && (user.playerids.length<1||user.playerids.includes(onesignalToken)==false) ){
                  this.onesignal.subscribeNotification(onesignalToken).subscribe((data)=>{
                    console.log('Subscribed : ',data)
                    localStorage.setItem('user',JSON.stringify(data))
                  }, (error)=>{
                    console.log('Subscription Failure :',error)
                  })
                }
              }else if(notify==='denied') {
                this.onesignal.unSubscribeNotification(onesignalToken).subscribe((data)=>{
                  console.log('unsubscribed : ',data)
                  localStorage.setItem('user',JSON.stringify(data))
                }, (error)=>{
                console.log('Subscribe Failure :',error)
                })
              } else{
                localStorage.setItem('nativePrompt','show')
                // this.notifyPrompt()
              }
          }
        }
      }
      notifyPrompt(){
        Notification.requestPermission().then(permission => {
          console.log(permission);
          localStorage.setItem('notify', permission);
          this.onesignalCheck()
        });
      }
}
