import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ServicesModule} from 'src/app/services/services.module';
import {APP_BASE_HREF} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {AccordionModule} from 'primeng/accordion';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CarouselModule} from 'primeng/carousel';
import {InputTextModule} from 'primeng/primeng';
import {ChartModule} from 'primeng/primeng';
import {CalendarModule} from 'primeng/primeng';
import {ButtonModule} from 'primeng/primeng';
import {MessagesModule} from 'primeng/primeng';
import {ConfirmDialogModule} from 'primeng/primeng';
import {DialogModule} from 'primeng/primeng';
import {SelectButtonModule} from 'primeng/primeng';
import {AutoCompleteModule} from 'primeng/primeng';
import {SharedModule} from 'primeng/primeng';
import {ToolbarModule} from 'primeng/primeng';
import {PanelModule} from 'primeng/primeng';
import {FieldsetModule} from 'primeng/primeng';
import {TooltipModule} from 'primeng/primeng';
import {TreeTableModule} from 'primeng/primeng';
import {getBaseLocation} from './helpers/common-functions.util';
import {LogibidCommonModule} from './common/logibid-common.module';
import {SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {AgmDirectionModule} from 'agm-direction';

import {NgxPermissionsModule} from 'ngx-permissions';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const sconfig: SocketIoConfig = { url: 'wss://api.logibids.com/', options: { transports: ['websocket'] } };

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2353628558270785')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('41842724806-kc2j1qejbb3kta5o3sh80dvo327sr4v5.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    LogibidCommonModule,
    BrowserModule,

    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServicesModule.forRoot(),
    AccordionModule,
    ToastModule,
    CarouselModule,
    InputTextModule,
    ChartModule,
    CalendarModule,
    ButtonModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,
    SelectButtonModule,
    AutoCompleteModule,
    SharedModule,
    AccordionModule,
    ToolbarModule,
    PanelModule,
    FieldsetModule,
    TooltipModule,
    TreeTableModule,
    SocialLoginModule,
    NgxPermissionsModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBOdShPph5r1xujbMvvK1NPpZn-T4QIoRQ',
      language: 'ar',
      libraries: ['places']
    }),
    AgmDirectionModule,
    SocketIoModule.forRoot(sconfig),
  ],
  providers: [
    GoogleMapsAPIWrapper,
    MessageService,
    ConfirmationService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
