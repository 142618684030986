export const environment = {
    production: true,
    apiBaseUrl: 'https://api.logibids.com/v1/',
    defaultLang: 'en',
    GOOGLE_API_KEY: 'AIzaSyBOdShPph5r1xujbMvvK1NPpZn-T4QIoRQ',
    baseUrl: 'https://driver.logibids.com',
    pusher: {
        key: '8a95c1ef06e601684784',
        cluster: "eu",
        authEndpoint: 'https://api.logibids.com/v1/pusher-authenticate'
    }
};
